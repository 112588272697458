import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-energy-station',
  templateUrl: './energy-station.component.html',
  styleUrls: ['./energy-station.component.css']
})
export class EnergyStationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}