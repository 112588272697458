import { Component, OnInit } from '@angular/core';


interface Review {
  author: string;
  review: string;
}

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.css']
})

export class ReviewsComponent implements OnInit {
  

  reviews: Review[] = [
    { author: "David T.", review: "Farid, Thank you We have been running the Solarworld panels and Enphase micro inverters you designed and installed for our home since December 2013. We have not paid an electric bill since. We recently made the last payment on the home equity loan - monthly payment was lower than the previous average electric bill. Last month we received another benefit: a $500 credit on our electric bill for our Chevy Bolt EV, so SDGE will definitely owe us the money at our true-up in November.  We bought the panels before we bought the electric car.  All my trips to Los Angeles are free--no electric nor gas bill! Do it sooner than later, and purchase to get the full benefit."},
    { author: "David T.", review: "Farid, Thank you We have been running the Solarworld panels and Enphase micro inverters you designed and installed for our home since December 2013. We have not paid an electric bill since. We recently made the last payment on the home equity loan - monthly payment was lower than the previous average electric bill. Last month we received another benefit: a $500 credit on our electric bill for our Chevy Bolt EV, so SDGE will definitely owe us the money at our true-up in November.  We bought the panels before we bought the electric car.  All my trips to Los Angeles are free--no electric nor gas bill! Do it sooner than later, and purchase to get the full benefit."}
  ];

  constructor() { }

  ngOnInit() {
  }

}
