import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommericalComponent } from 'src/app/components/modals/video/commerical/commerical.component';
import { VideoComponent } from 'src/app/components/modals/video/video.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  bsModalRef: BsModalRef;
  
  constructor(private modalService: BsModalService) { }

  ngOnInit() {
  }

  onPlayVideo(id: string) {
    const initialState = {
      id: id,
      title: 'Video'
    };
    this.bsModalRef = this.modalService.show(VideoComponent, {initialState});
    this.bsModalRef.content.closeBtnName = 'Close';
  }

}
