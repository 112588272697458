import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as emailjs from 'emailjs-com';

@Component({
  selector: 'app-contactform',
  templateUrl: './contactform.component.html',
  styleUrls: ['./contactform.component.css']
})
export class ContactformComponent implements OnInit {
  isValidSubmission = false;
  constructor() { }

  ngOnInit() {
  }

  onSubmit( form: NgForm ) {

    let postVars = {
      email: form.value.email,
      name: form.value.name,
      phone: form.value.phone
    };

    if (form.valid) {
        emailjs.send('mailgun','solar_fuze_contact', postVars, 'user_rWdgDf5jTmWHs8ztNmGUQ').then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        this.isValidSubmission = true;
        }, (err) => {
        console.log('FAILED...', err);
      });
    }
  }

}
