import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './containers/pages/home/home.component';
import { AboutUsComponent } from './containers/pages/about-us/about-us.component';
import { LocationsComponent } from './containers/pages/locations/locations.component';
import { ServicesComponent } from './containers/pages/services/services.component';
import { ContactComponent } from './containers/pages/contact/contact.component';
import { FaqComponent } from './containers/pages/faq/faq.component';
import { SanDiegoComponent } from './containers/pages/locations/location/san-diego/san-diego.component';
import { PonteBeachComponent } from './containers/pages/locations/location/ponte-beach/ponte-beach.component';
import { FairfaxComponent } from './containers/pages/locations/location/fairfax/fairfax.component';
import { CarportComponent } from './containers/pages/services/carport/carport.component';
import { RooftopComponent } from './containers/pages/services/rooftop/rooftop.component';
import { SolarFarmsComponent } from './containers/pages/services/solar-farms/solar-farms.component';
import { SolarCanopyComponent } from './containers/pages/services/solar-canopy/solar-canopy.component';
import { GroundMountComponent } from './containers/pages/services/ground-mount/ground-mount.component';
import { GroundIndustrialComponent } from './containers/pages/services/ground-industrial/ground-industrial.component';
import { BatteryComponent } from './containers/pages/services/battery/battery.component';
import { RoofingComponent } from './containers/pages/services/roofing/roofing.component';
import { EvComponent } from './containers/pages/services/ev/ev.component';
import { ElectricalComponent } from './containers/services/electrical/electrical.component';
import { TestimonalPageComponent } from './containers/pages/testimonials/testimonal-page/testimonal-page.component';
import { EnergyStationComponent } from './containers/pages/services/energy-station/energy-station.component';

const routes: Routes = [
  {path:"", component:HomeComponent},
  {path:"home", component:HomeComponent},
  {path:"about", component:AboutUsComponent},
  {path:"locations", component:LocationsComponent},
  {path:"locations/california", component:SanDiegoComponent},
  {path:"locations/florida", component:PonteBeachComponent},
  {path:"locations/virginia", component:FairfaxComponent},
  {path:"services", component:ServicesComponent},
  {path:"services/carport", component:CarportComponent},
  {path:"services/rooftop", component:RooftopComponent},
  {path:"services/solarfarms", component:SolarFarmsComponent},
  {path:"services/solarcanopy", component:SolarCanopyComponent},
  {path:"services/groundmount", component:GroundMountComponent},
  {path:"services/industrial", component:GroundIndustrialComponent},
  {path:"contact", component:ContactComponent},
  {path:"faq", component:FaqComponent},
  {path:"services/battery", component:BatteryComponent},
  {path:"services/roofing", component:RoofingComponent},
  {path:"services/ev", component:EvComponent},
  {path:"services/electrical", component:ElectricalComponent},
  {path:"reviews", component:TestimonalPageComponent},
  {path:"services/energystation", component: EnergyStationComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
