import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ground-industrial',
  templateUrl: './ground-industrial.component.html',
  styleUrls: ['./ground-industrial.component.css']
})
export class GroundIndustrialComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
