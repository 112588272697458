import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-solar-canopy',
  templateUrl: './solar-canopy.component.html',
  styleUrls: ['./solar-canopy.component.css']
})
export class SolarCanopyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
