import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-five-star',
  templateUrl: './five-star.component.html',
  styleUrls: ['./five-star.component.css']
})
export class FiveStarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
