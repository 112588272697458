import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { QuoteComponent } from '../modals/quote/quote.component';
import { createOfflineCompileUrlResolver } from '@angular/compiler';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  isCollapsed = true;
  bsModalRef: BsModalRef;
  
  constructor(private modalService: BsModalService) { }

  ngOnInit() {
  }

  GetAQuote() {
    this.isCollapsed = !this.isCollapsed;
    const initialState = {
      title: 'Get A Quote Today!'
    };
    this.bsModalRef = this.modalService.show(QuoteComponent, {initialState});
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  onHidden(): void {
    console.log('Dropdown is hidden');
  }
  onShown(): void {
    console.log('Dropdown is shown');
  }
  isOpenChange(): void {
    console.log('Dropdown state is changed');
  }

  onLogoClick():void {
    console.log("test");
  }

}
