import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carport',
  templateUrl: './carport.component.html',
  styleUrls: ['./carport.component.css']
})
export class CarportComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
