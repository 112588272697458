import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-roofing',
  templateUrl: './roofing.component.html',
  styleUrls: ['./roofing.component.css']
})
export class RoofingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
