import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { VideoComponent } from 'src/app/components/modals/video/video.component';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  bsModalRef: BsModalRef;

  constructor(private modalService: BsModalService) { }

  ngOnInit() {
  }

  onPlayVideo(id: string) {
    const initialState = {
      id: id,
      title: 'Video'
    };
    this.bsModalRef = this.modalService.show(VideoComponent, {initialState});
    this.bsModalRef.content.closeBtnName = 'Close';
  }
}
