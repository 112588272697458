import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal/';

@Component({
  selector: 'app-commerical',
  templateUrl: './commerical.component.html',
  styleUrls: ['./commerical.component.css']
})
export class CommericalComponent implements OnInit {

  constructor( public bsModalRef: BsModalRef ) { }

  ngOnInit() {
  }

}
