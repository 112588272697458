import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { VideoComponent } from 'src/app/components/modals/video/video.component';

@Component({
  selector: 'app-testimonal-page',
  templateUrl: './testimonal-page.component.html',
  styleUrls: ['./testimonal-page.component.css']
})
export class TestimonalPageComponent implements OnInit {
  bsModalRef: BsModalRef;
  
  constructor(private modalService: BsModalService) { }

  ngOnInit() {
  }

  onPlayVideo(id: string) {
    const initialState = {
      id: id,
      title: 'Video'
    };
    this.bsModalRef = this.modalService.show(VideoComponent, {initialState});
    this.bsModalRef.content.closeBtnName = 'Close';
  }

}
