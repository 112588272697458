import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './containers/layout/layout.component';
import { HomeComponent } from './containers/pages/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';

import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';


import { AboutUsComponent } from './containers/pages/about-us/about-us.component';
import { LocationsComponent } from './containers/pages/locations/locations.component';
import { ServicesComponent } from './containers/pages/services/services.component';
import { ContactComponent } from './containers/pages/contact/contact.component';
import { FaqComponent } from './containers/pages/faq/faq.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { LocationCarouselComponent } from './components/carousel/location-carousel/location-carousel.component';
import { SanDiegoComponent } from './containers/pages/locations/location/san-diego/san-diego.component';
import { PonteBeachComponent } from './containers/pages/locations/location/ponte-beach/ponte-beach.component';
import { FairfaxComponent } from './containers/pages/locations/location/fairfax/fairfax.component';
import { CommericalComponent } from './components/modals/video/commerical/commerical.component';
import { JumbotronComponent } from './components/jumbotron/jumbotron.component';
import { VideoComponent } from './components/modals/video/video.component';
import { QuoteComponent } from './components/modals/quote/quote.component';
import { CarportComponent } from './containers/pages/services/carport/carport.component';
import { RooftopComponent } from './containers/pages/services/rooftop/rooftop.component';
import { SolarFarmsComponent } from './containers/pages/services/solar-farms/solar-farms.component';
import { SolarCanopyComponent } from './containers/pages/services/solar-canopy/solar-canopy.component';
import { GroundMountComponent } from './containers/pages/services/ground-mount/ground-mount.component';
import { GroundIndustrialComponent } from './containers/pages/services/ground-industrial/ground-industrial.component';
import { FormsModule }   from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ContactformComponent } from './components/forms/contactform/contactform.component';
import { BatteryComponent } from './containers/pages/services/battery/battery.component';
import { RoofingComponent } from './containers/pages/services/roofing/roofing.component';
import { EnergyStationComponent } from './containers/pages/services/energy-station/energy-station.component';
import { ServicesListComponent} from './containers/pages/services/services-list/services-list.component';
import { EvComponent } from './containers/pages/services/ev/ev.component';
import { FiveStarComponent } from './components/ui/five-star/five-star.component';
import { ReviewsComponent } from './components/ui/reviews/reviews.component';
import { TestimonialsComponent } from './containers/pages/testimonials/testimonials.component';
import { ElectricalComponent } from './containers/services/electrical/electrical.component';
import { TestimonalPageComponent } from './containers/pages/testimonials/testimonal-page/testimonal-page.component';
@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HomeComponent,
    HeaderComponent,
    NavbarComponent,
    FooterComponent,
    AboutUsComponent,
    LocationsComponent,
    ServicesComponent,
    ContactComponent,
    FaqComponent,
    CarouselComponent,
    LocationCarouselComponent,
    SanDiegoComponent,
    PonteBeachComponent,
    FairfaxComponent,
    CommericalComponent,
    JumbotronComponent,
    VideoComponent,
    QuoteComponent,
    CarportComponent,
    RooftopComponent,
    SolarFarmsComponent,
    SolarCanopyComponent,
    GroundMountComponent,
    GroundIndustrialComponent,
    ContactformComponent,
    BatteryComponent,
    RoofingComponent,
    ServicesListComponent,
    EvComponent,
    FiveStarComponent,
    ReviewsComponent,
    TestimonialsComponent,
    ElectricalComponent,
    TestimonalPageComponent,
    EnergyStationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    CollapseModule.forRoot(),
    CarouselModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    AccordionModule.forRoot(),
    BsDropdownModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [
    CommericalComponent,
    VideoComponent,
    QuoteComponent
  ]
})
export class AppModule { }
