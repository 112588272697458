import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ground-mount',
  templateUrl: './ground-mount.component.html',
  styleUrls: ['./ground-mount.component.css']
})
export class GroundMountComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
