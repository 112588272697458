import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {
  dangerousVideoURL: string;
  videoURL: SafeResourceUrl;
  title: string;
  id: string;

  constructor(public bsModalRef: BsModalRef, private sanitizer: DomSanitizer) { }

  ngOnInit() {

  }

  getSanitizedURL() {
    this.dangerousVideoURL = 'https://www.youtube.com/embed/' + this.id;
    this.videoURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousVideoURL);
    return this.videoURL;
  }
}
